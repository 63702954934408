import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { FormattedMessage } from "react-intl";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Modal({
    open,
    setOpen,
    children,
    title,
}: {
    open: boolean;
    setOpen?: (e: boolean) => void;
    children: React.ReactNode;
    title: string;
}) {
    return (
        <Dialog
            open={open}
            onClose={() => (setOpen ? setOpen(false) : null)}
            className="relative z-50"
        >
            <DialogBackdrop className="fixed inset-0 bg-black/30" />

            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                <DialogPanel className="max-w-lg w-128 space-y-4 border bg-white p-8 rounded-lg">
                    <div className="flex gap-x-4">
                        <DialogTitle className="text-2xl text-left">
                            <FormattedMessage id={title} />
                        </DialogTitle>
                        {setOpen && (
                            <button onClick={() => setOpen(false)}>
                                <XMarkIcon className="size-6" />
                                <span className="sr-only">
                                    <FormattedMessage id="Close" />
                                </span>
                            </button>
                        )}
                    </div>

                    {children}
                </DialogPanel>
            </div>
        </Dialog>
    );
}
