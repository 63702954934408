import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "motion/react";
import { router } from "@inertiajs/react";
import CompletedPage from "./cardComponents/completed";
import FollowUp from "./cardComponents/followUp";
import AcceptFollowUp from "./cardComponents/acceptFollowUp";
import Results from "./cardComponents/results";
import CardFront from "./cardComponents/cardFront";
import { Poll } from "@/types/poll";
import InitialQuestion from "./cardComponents/initialQuestion";

interface PollQuestionInterface {
    setHovering: (e: boolean) => void;
    flip: (e: boolean) => void;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    flipped?: boolean;
    mock?: boolean;
    poll: Poll & { imageUrl: string };
    page: number;
}

export function QuestionWrapper({ progress, page, children, flip }) {
    return (
        <div
            className={`rounded-xl border flex flex-col max-h-full min-h-128 overflow-y-auto bg-white`}
            onClick={(e) => {
                // @ts-ignore
                if (e.target?.id !== "back") {
                    flip(true);
                }
            }}
        >
            <div className="w-full h-1 bg-gray-50">
                <motion.div
                    className="h-full bg-voice"
                    style={{ width: `${progress * 100}%` }}
                    layout
                />
            </div>
            <div
                className={`${page !== 3 ? "p-10" : ""} flex flex-col h-full grow`}
            >
                {children}
            </div>
        </div>
    );
}

export function Question({
    setHovering,
    flip,
    page,
    setPage,
    mock = false,
    poll,
}: PollQuestionInterface) {
    const [selected, setSelected] = useState<string[]>([]);
    const reset = () => {
        router.reload({
            onSuccess: () => {
                setHovering(false);
                flip(false);
            },
        });
    };

    const [progress, setProgress] = useState(0);
    return (
        <QuestionWrapper progress={progress} page={page} flip={flip}>
            {page === 1 && (
                <InitialQuestion
                    question={poll.questions[0]}
                    selected={selected}
                    setSelected={setSelected}
                    reset={() => flip(false)}
                    setPage={setPage}
                    setProgress={setProgress}
                    mock={mock}
                />
            )}
            {page === 2 && (
                <Results
                    question={poll.questions[0]}
                    next={() =>
                        setPage((prev) => (poll.questions?.[1] ? prev + 1 : 5))
                    }
                    selected={selected}
                    pollId={poll.id}
                    mock={mock}
                />
            )}
            {page === 3 && (
                <AcceptFollowUp
                    previous={() => {
                        setPage((prev) => prev + 2);
                        setProgress(1);
                    }}
                    next={() => setPage((prev) => prev + 1)}
                    question={poll.questions?.[1]}
                    coverImage={poll.imageUrl}
                />
            )}
            {page === 4 && (
                <FollowUp
                    previous={() => setPage((prev) => prev + 1)}
                    next={() => setPage((prev) => prev + 1)}
                    setProgress={setProgress}
                    question={poll.questions?.[1]}
                    mock={mock}
                />
            )}
            {page === 5 && (
                <CompletedPage reset={reset} setProgress={setProgress} />
            )}
        </QuestionWrapper>
    );
}

export default function PollCard({
    poll,
    mock = false,
    setCompleteProfileOpen,
}: {
    poll: Poll & { imageUrl: string };
    mock?: boolean;
    setCompleteProfileOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    // This state tracks if the user has clicked the card
    const [flipped, setFlipped] = useState(false);
    // This state tracks if the user currently has their mouse over the card
    const [hovering, setHovering] = useState(false);
    const [page, setPage] = useState(0);
    useEffect(() => {
        if ((flipped || hovering) && page === 0) {
            setPage(1);
        }
        if (!flipped && !hovering) {
            setPage(0);
        }
    }, [flipped, hovering, page]);
    return (
        <AnimatePresence>
            <div className="rounded-xl overflow-y-auto">
                {page >= 1 ? (
                    <Question
                        flip={setFlipped}
                        setHovering={setHovering}
                        page={page}
                        setPage={setPage}
                        poll={poll}
                        mock={mock}
                    />
                ) : (
                    <CardFront
                        flip={setFlipped}
                        setCompleteProfileOpen={setCompleteProfileOpen}
                        question={poll.questions[0]}
                        topics={poll.topics}
                        coverImage={poll.imageUrl}
                        voteCount={poll.vote_count}
                        closingAt={poll.closing_at}
                    />
                )}
            </div>
        </AnimatePresence>
    );
}
