import Button from "@/components/common/buttons/button";
import { FormattedMessage } from "react-intl";

interface ProgressButtonsInterface {
    previous?: () => void;
    next: () => void;
    nextLabel?: string;
    previousLabel?: string;
}

export function ProgressButtons({
    previous,
    next,
    nextLabel = "Next",
    previousLabel = "Back",
}: ProgressButtonsInterface) {
    return (
        <div className="grow flex-col flex justify-end">
            <div className="flex pt-4">
                {previous && (
                    <button
                        id="back"
                        className="text-voice z-10 hover:bg-red-50 px-4 mr-4 rounded-lg focus:ring-2 focus:ring-voice focus:outline-hidden"
                        onClick={previous}
                    >
                        <FormattedMessage id={previousLabel} />
                    </button>
                )}
                <Button
                    color="voice"
                    className="w-full text-center justify-center"
                    onClick={next}
                >
                    <FormattedMessage id={nextLabel} />
                </Button>
            </div>
        </div>
    );
}
