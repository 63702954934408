export default function AustraliaBanner() {
    return (
        <div>
            <div className="relative h-28 sm:h-40 w-full">
                <img
                    src="/images/au/monash-people.webp"
                    className="w-full object-cover object-center overflow-hidden absolute h-28 sm:h-40 opacity-80 bg-linear-to-r from-white to-gray-900"
                    alt=""
                    style={{ objectPosition: "50% 30%" }}
                />
                <div className="w-full h-full absolute bg-linear-to-r from-transparent from-25% via-white via-55% sm:via-60% to-white z-10 "></div>

                <img
                    src="/images/au/monash-colours-1.svg"
                    className="w-full object-cover overflow-hidden absolute h-28 sm:h-40 z-20 opacity-75"
                    alt=""
                    style={{ objectPosition: "25% 70%" }}
                />
                <div className="h-full text-white relative z-20 max-w-7xl mx-auto px-6 py-8 font-light flex items-center justify-between">
                    <div>
                        <img
                            src="/images/au/main-logo.svg"
                            className="h-8 sm:h-16 md:h-20"
                            alt=""
                        />
                        <p className="hidden lg:block text-sm sm:text-base leading-none">
                            Connecting community voices to transform research
                        </p>
                    </div>
                    <img
                        src="/images/au/partnership-logo.svg"
                        className="h-8 sm:h-12"
                        alt=""
                    />
                </div>
            </div>
            <div className="w-full text-sm sm:text-base text-white bg-monash">
                <p className="max-w-7xl mx-auto px-6 py-1">
                    Voice™ Australia is supported by the National Centre for
                    Healthy Ageing (NCHA) as part of the global Voice network to
                    involve communities in research and innovation.
                    <a
                        href="https://www.monash.edu/medicine/national-centre-for-healthy-ageing/about-us"
                        className="underline ml-2"
                        target="_blank" rel="noreferrer"
                    >
                        Learn more
                    </a>
                </p>
            </div>
        </div>
    );
}
