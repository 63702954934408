import { usePage } from "@inertiajs/react";
import CheckboxOptions from "./checkboxOptions";
import RadioOptions from "./radioOptions";
import axios from "axios";
import toast from "react-hot-toast";

export default function InitialQuestion({
    question,
    selected,
    setSelected,
    reset,
    setPage,
    setProgress,
    mock,
}) {
    const { csrfToken } = usePage().props;

    const saveFirstQuestion = async (callback) => {
        if (mock) {
            callback();
        } else {
            try {
                await axios.post(
                    route("polls.user.vote", question.id),
                    { question_data: selected },
                    {
                        headers: {
                            "X-Csrf-Token": csrfToken,
                        },
                    },
                );
                callback();
            } catch (e) {
                console.error(e);
                toast.error("Saving failed, please try again");
            }
        }
    };
    return (
        <fieldset className="contents">
            <legend className="text-xl pb-1">{question.question}</legend>
            <span className="pb-1">{question.description}</span>
            {question.option_type === "radio" && (
                <RadioOptions
                    selected={selected?.[0] || undefined}
                    setSelected={setSelected}
                    options={question.options || []}
                    previous={reset}
                    next={() => {
                        saveFirstQuestion(() => setPage((prev) => prev + 1));
                    }}
                    setProgress={setProgress}
                />
            )}
            {question.option_type === "checkbox" && (
                <CheckboxOptions
                    options={question.options || []}
                    selected={selected}
                    setSelected={setSelected}
                    previous={reset}
                    next={() => {
                        saveFirstQuestion(() => setPage((prev) => prev + 1));
                    }}
                    setProgress={setProgress}
                />
            )}
        </fieldset>
    );
}
