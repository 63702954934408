import { FormattedMessage, useIntl } from "react-intl";
import Modal from "./modal";
import Input from "../forms/input";
import Error from "@/components/common/forms/error";
import { useForm, usePage } from "@inertiajs/react";
import { capitalizeFirstLetter } from "@/utils/index";
import Button from "../buttons/button";
import Select from "../forms/select";
import { indigenous_options } from "../../../data/form_options";

export default function CompleteProfileModal({
    genders,
    ethnicities,
    sexualities,
    criteria = false,
    open,
    setOpen,
}: {
    genders: {
        legend: string;
    }[];
    ethnicities: {
        legend: string;
    }[];
    sexualities: {
        legend: string;
    }[];
    criteria?: { label: string }[] | false;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const intl = useIntl();
    const { auth, realm } = usePage().props;
    const { data, setData, post, transform, errors } = useForm({
        dob: {
            dd: "",
            mm: "",
            yyyy: "",
        },
        gender: "",
        ethnicity: "",
        sexual_orientation: "",
        post_code: "",
        indigenous_status: "",
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        post(route("update-user", auth.user.id), {
            onSuccess: () => setOpen(false),
        });
    };

    // @ts-ignore
    const needsDob = auth.user.age_range === "Unknown";
    const needsSexualOrientation =
        criteria &&
        !!criteria.find(
            (criterion) => criterion.label === "sexual orientation",
        ) &&
        !auth.user.sexual_orientation;
    transform((data) =>
        // @ts-ignore
        ({
            ...data,
            // @ts-ignore
            dob: needsDob
                ? data.dob.yyyy &&
                  data.dob.mm &&
                  data.dob.dd &&
                  `${data.dob.yyyy}-${data.dob.mm}-${data.dob.dd}`
                : undefined,
            // @ts-ignore
            gender: !auth.user.gender ? data.gender : undefined,
            // @ts-ignore
            ethnicity: !auth.user.ethnicity ? data.ethnicity : undefined,
            // @ts-ignore
            sexual_orientation: needsSexualOrientation
                ? data.sexual_orientation
                : undefined,
            // @ts-ignore
            post_code: !auth.user.post_code ? data.post_code : undefined,
            // @ts-ignore
            indigenous_status: !auth.user.indigenous_status
                ? data.indigenous_status
                : undefined,
        }),
    );
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Complete your profile to get started"
        >
            <p>
                <FormattedMessage id="In order to take part in this Voice activity, we require the following pieces of information. Researchers will use your profile when looking at applications to ensure they have diverse range of participants." />
            </p>
            <form
                className="flex flex-col gap-y-4 text-sm"
                onSubmit={handleSubmit}
            >
                {needsDob && (
                    <fieldset>
                        <label>
                            <FormattedMessage id="Date of Birth" />
                        </label>
                        <div className="flex gap-x-4 pt-1">
                            <div className="flex flex-col">
                                <label htmlFor="id">
                                    <FormattedMessage id="Day" />
                                </label>
                                <Input
                                    className="h-10 w-16 px-2"
                                    type="number"
                                    min={1}
                                    max={31}
                                    id="day"
                                    value={data.dob.dd}
                                    onChange={(e) =>
                                        setData("dob", {
                                            ...data.dob,
                                            dd: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="month">
                                    <FormattedMessage id="Month" />
                                </label>
                                <Input
                                    className="h-10 w-16 px-2"
                                    type="number"
                                    id="month"
                                    min={1}
                                    max={12}
                                    value={data.dob.mm}
                                    onChange={(e) =>
                                        setData("dob", {
                                            ...data.dob,
                                            mm: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="year">
                                    <FormattedMessage id="Year" />
                                </label>
                                <Input
                                    className="h-10 w-24 px-2"
                                    type="number"
                                    id="year"
                                    min={1900}
                                    value={data.dob.yyyy}
                                    onChange={(e) =>
                                        setData("dob", {
                                            ...data.dob,
                                            yyyy: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <Error error={errors.dob} />
                    </fieldset>
                )}
                {!auth.user.gender && (
                    <div className="flex flex-col">
                        <label htmlFor="gender">
                            <FormattedMessage id="Gender identity" />
                        </label>
                        <Select
                            id="gender"
                            value={data.gender}
                            onChange={(e) => setData("gender", e.target.value)}
                        >
                            <option value="" disabled>
                                <FormattedMessage id="Select" />
                            </option>
                            {genders.map((gender) => (
                                <option
                                    value={gender.legend}
                                    key={gender.legend}
                                >
                                    {capitalizeFirstLetter(gender.legend)}
                                </option>
                            ))}
                        </Select>
                        <Error error={errors.gender} />
                    </div>
                )}
                {!auth.user.ethnicity && (
                    <div className="flex flex-col">
                        <label htmlFor="ethnicity">
                            <FormattedMessage
                                id={
                                    realm.country === "AU"
                                        ? "Ancestry"
                                        : "Ethnicity"
                                }
                            />
                        </label>
                        <Select
                            id="ethnicity"
                            value={data.ethnicity}
                            onChange={(e) =>
                                setData("ethnicity", e.target.value)
                            }
                        >
                            <option value="" disabled>
                                <FormattedMessage id="Select" />
                            </option>
                            {ethnicities.map((ethnicity) => (
                                <option
                                    value={ethnicity.legend}
                                    key={ethnicity.legend}
                                >
                                    {capitalizeFirstLetter(ethnicity.legend)}
                                </option>
                            ))}
                        </Select>
                        <Error error={errors.ethnicity} />
                    </div>
                )}
                {realm.country === "AU" && !auth.user.indigenous_status && (
                    <div className="flex flex-col">
                        <label htmlFor="indigenous_status">
                            <FormattedMessage id="Indigenous status" />
                        </label>
                        <Select
                            id="indigenous_status"
                            value={data.indigenous_status}
                            onChange={(e) =>
                                setData("indigenous_status", e.target.value)
                            }
                        >
                            <option value="" disabled>
                                <FormattedMessage id="Select" />
                            </option>
                            {indigenous_options.map((option) => (
                                <option value={option} key={option}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                        <Error error={errors.indigenous_status} />
                    </div>
                )}
                {needsSexualOrientation && (
                    <div>
                        <label htmlFor="sexuality">
                            <FormattedMessage id="Sexual Orientation" />
                        </label>
                        <Select
                            id="sexuality"
                            value={data.sexual_orientation}
                            onChange={(e) =>
                                setData("sexual_orientation", e.target.value)
                            }
                        >
                            <option value="" disabled>
                                <FormattedMessage id="Select" />
                            </option>
                            {sexualities.map((sexuality) => (
                                <option
                                    value={sexuality.legend}
                                    key={sexuality.legend}
                                >
                                    {capitalizeFirstLetter(sexuality.legend)}
                                </option>
                            ))}
                        </Select>
                        <Error error={errors.sexual_orientation} />
                    </div>
                )}
                {!auth.user.post_code && (
                    <div className="grid gap-2">
                        <label htmlFor="post-code">
                            <FormattedMessage id="Postcode" />
                        </label>
                        <Input
                            id="post-code"
                            aria-label={intl.formatMessage({ id: "Postcode" })}
                            className="w-full h-12 px-2"
                            value={data.post_code}
                            onChange={(e) =>
                                setData("post_code", e.target.value)
                            }
                        />
                        <Error error={errors.post_code} />
                    </div>
                )}
                <div className="flex justify-end gap-x-4">
                    <button type="button" onClick={() => setOpen(false)}>
                        <FormattedMessage id="Cancel" />
                    </button>
                    <Button color="black" type="submit">
                        <FormattedMessage id="Update profile" />
                    </Button>
                </div>
            </form>
        </Modal>
    );
}
