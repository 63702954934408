import { Head, usePage } from "@inertiajs/react";
import { Toaster } from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import CookieNotice from "@/components/common/modals/cookieNotice";
import RealmRedirectBanner from "@/components/common/banners/RealmRedirectBanner";
import { Helmet } from "react-helmet";

interface BaseLayoutProps {
    children: React.ReactNode;
    title: string;
    description?: string;
    image?: string;
}

export default function BaseLayout({ children, title }: BaseLayoutProps) {
    const { ipInfo, realm, locale } = usePage().props;
    return (
        <>
            <Helmet htmlAttributes={{ lang: locale }} />
            <Head>
                <title>{`Voice: ${title}`}</title>
                <script
                    type="text/javascript"
                    src="https://api.reciteme.com/asset/js?key=72e4488b3161684d82a6186e04c5647afdbdbf0e"
                ></script>
            </Head>
            <CookieNotice />
            <a
                className="absolute sr-only focus:not-sr-only focus:absolute! z-1000"
                href="#main-content"
            >
                <FormattedMessage id="Skip to main content" />
            </a>
            <Toaster position="top-right" />

            <div>
                {ipInfo &&
                    ipInfo.iso_code === "CA" &&
                    realm.country !== "CA" &&
                    !sessionStorage?.getItem("realmDismiss") && (
                        <RealmRedirectBanner
                            text="It looks like you’re trying to access our site from Canada. Would you like go to the Voice Canada site where content may be different?"
                            link="https://ca.voice-global.org/"
                        />
                    )}
            </div>
            {children}
        </>
    );
}
